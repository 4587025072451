import React, { useState, useEffect } from 'react';
import {
    Box, TablePagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, Button, Chip, Collapse, IconButton, Typography, Dialog, DialogTitle, Alert,
    Autocomplete, DialogActions, DialogContent, Grid, Snackbar, TextField,
} from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import MainCard from 'components/MainCard';
// import LinearProgress from '@mui/material/LinearProgress';
import Draggable from 'react-draggable';
import { MdRemove, MdAdd, MdSave, MdDeleteOutline } from "react-icons/md";
import { LoadingButton } from '@mui/lab';
import { UpOutlined, DownOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { CircularProgress, FormControl, MenuItem, Select, InputLabel, ToggleButtonGroup, ToggleButton, ImageList, ImageListItem, ImageListItemBar, ListSubheader, Stack, Avatar, Checkbox } from '../../../node_modules/@mui/material/index';
// import OutlinedInput from 'themes/overrides/OutlinedInput';
// import { deepOrange, green } from '@mui/material/colors';
import { useMediaQuery } from "@mui/material";

function Row(props) {
    const { row, edit, set_dialog_item, set_dialog_edit_is_open } = props;
    const [checked, set_checked] = React.useState(false);
    const [open_subrow, set_open_subrow] = React.useState(false)
    const [open_subrow2, set_open_subrow2] = React.useState(false)
    const [edit_loading, set_edit_loading] = React.useState(false)

    useEffect(() => {
        new Promise((resolve, reject) => {
            setTimeout(() => {
                set_checked(!checked);
            }, 500);
        });
    }, [checked]);

    useEffect(() => {
        if (!props?.edit_loading) set_edit_loading(false);
    }, [props?.edit_loading]);


    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                {/* {(props.settings?.filters[0][0] ?? '') == 'company1_id' ? 'a' : 'b'} */}
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => set_open_subrow(!open_subrow)} >
                        {open_subrow ? <UpOutlined /> : <DownOutlined />}
                    </IconButton>
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{row?.ref}</TableCell>
                <TableCell>{row?.created_at && moment(row?.created_at)?.format('DD/MM HH:mm')}</TableCell>
                <TableCell>{row?.company?.name}</TableCell>
                <TableCell>{row?.user?.name}</TableCell>
                <TableCell>{row?.company1?.name}</TableCell>
                <TableCell>{row?.user1?.name}</TableCell>
                <TableCell>
                    <Chip label={row?.status?.value} sx={{ color: row?.status?.color, bgcolor: row?.status?.bgcolor }} />
                </TableCell>
                <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
                    {edit_loading ?
                        <CircularProgress size={30} />
                        :
                        <>
                            {row?.status_key && row?.status_key == 10 && JSON.parse(localStorage.getItem('company'))?.id == row?.company1_id && (
                                <Button variant="outlined" color="success" onClick={() => { set_edit_loading(true), edit({ id: row?.id, status_key: 20 }) }}>
                                    Confirmer
                                </Button>
                            )}
                            {row?.status_key && row?.status_key == 300 && (
                                <Button variant="outlined" color="success" onClick={() => { set_edit_loading(true), edit({ id: row?.id, status_key: 310 }) }}>
                                    Confirmer
                                </Button>
                            )}
                            {row?.status_key && row?.status_key == 10 && (
                                <Button variant="outlined" color="warning" sx={{ ml: 1 }} onClick={() => { set_edit_loading(true), edit({ id: row?.id, status_key: 30 }) }}>
                                    Annuler
                                </Button>
                            )}
                            {/* {row?.status_key && row?.status_key == 10 && (
                                <Button variant="outlined" color="warning" sx={{ ml: 1 }} onClick={() => edit({ id: row?.id, status_key: 30 })}>
                                    Envoye Email
                                </Button>
                            )} */}
                            {row?.status_key && row?.status_key == 10 && (
                                <Button variant="outlined" color="primary" sx={{ ml: 1 }}
                                    onClick={() => {
                                        set_dialog_edit_is_open(true);
                                        set_dialog_item(row);
                                    }}
                                >
                                    Edit
                                </Button>
                            )}
                            {props.permissions?.includes('commandes.received.table.btn_delete') &&
                                <Button variant="outlined" color="error" sx={{ ml: 1 }} onClick={() => props.delete1({ id: row?.id })}>
                                    Supprimer
                                </Button>
                            }
                        </>
                    }
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                    <Collapse in={open_subrow} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1, ml: { xs: 0, sm: 6 } }}>
                            {/* <Typography variant="h6" gutterBottom component="div">
                            </Typography> */}
                            {/* {(props.settings?.filters[0][0] ?? '') == 'company_id' ? <> */}
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        {/* <TableCell>Produit Externe</TableCell> */}
                                        <TableCell>Produit</TableCell>
                                        <TableCell>Qte</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row?.operation_details?.length > 0 && row?.operation_details.map((operation_details, i) => (
                                        <TableRow key={i}>
                                            {/* <TableCell>{operation_details?.product1?.name}</TableCell> */}
                                            <TableCell>{operation_details?.product?.name}</TableCell>
                                            <TableCell>{operation_details?.product1_qte}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            {/* </> : <>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Produit MP</TableCell>
                                            <TableCell>Qte Besoin</TableCell>
                                            <TableCell>Qte Existante</TableCell>
                                            <TableCell>Qte Manquée</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Produit d'Achat</TableCell>
                                            <TableCell>Qte Besoin</TableCell>
                                            <TableCell>Qte Existante</TableCell>
                                            <TableCell>Stock</TableCell>
                                            <TableCell>Qte Comandée</TableCell>
                                            <TableCell>
                                                <Button variant="contained" color="primary"
                                                    onClick={() => {
                                                        // console.log(row?.operation_details_mp);

                                                        // console.log({
                                                        //     company1_id: 3,
                                                        //     status_key: 10,
                                                        //     operation_details: row?.operation_details_mp
                                                        //         ?.filter(odmp => odmp?.product1_id != null)
                                                        //         ?.filter(odmp => odmp?.parent_qte_cmd != 0)
                                                        //         ?.map(odmp => ({
                                                        //             product1_id: odmp?.product1_id ?? 0,
                                                        //             product1_qte: odmp?.parent_qte_cmd ?? 0,
                                                        //             product_id: odmp?.product_id ?? 0,
                                                        //         }))
                                                        // });

                                                        edit({
                                                            company1_id: 3,
                                                            status_key: 10,
                                                            operation_details: row?.operation_details_mp
                                                                ?.filter(odmp => odmp?.product_parent_id != null)
                                                                ?.filter(odmp => odmp?.parent_qte_cmd != 0)
                                                                ?.map(odmp => ({
                                                                    product1_id: odmp?.product_parent_id ?? 0,
                                                                    product1_qte: odmp?.parent_qte_cmd ?? 0,
                                                                    product_id: odmp?.product1_parent_id ?? 0,
                                                                }))
                                                        })
                                                    }}
                                                >
                                                    Commander
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row?.operation_details_mp?.length > 0 && row?.operation_details_mp.map((operation_detail, i) => (
                                            <TableRow key={i}>
                                                <TableCell>{operation_detail?.product_name}</TableCell>
                                                <TableCell>{operation_detail?.qte_reserved}</TableCell>
                                                <TableCell>{operation_detail?.qte_exists}</TableCell>
                                                <TableCell>{operation_detail?.qte_need}</TableCell>
                                                <TableCell>{operation_detail?.status ? <CheckCircleOutlined style={{ color: 'green' }} /> : <CloseCircleOutlined style={{ color: 'red' }} />}</TableCell>
                                                <TableCell>{operation_detail?.product2_name}</TableCell>
                                                <TableCell>{operation_detail?.parent_qte_need}</TableCell>
                                                <TableCell>{operation_detail?.parent_qte_exists}</TableCell>
                                                <TableCell>{operation_detail?.qte_need == 0 || operation_detail?.product2_name && !operation_detail?.parent_qte_cmd ? <CheckCircleOutlined style={{ color: 'green' }} /> : <CloseCircleOutlined style={{ color: 'red' }} />}</TableCell>
                                                <TableCell>{operation_detail?.parent_qte_cmd}</TableCell>
                                                <TableCell>
                                                    <TextField
                                                        type="number"
                                                        value={operation_detail?.parent_qte_cmd}
                                                        onChange={(e) => {
                                                            operation_detail.parent_qte_cmd = e.target.value
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </>} */}

                        </Box>
                    </Collapse>
                    <Collapse in={open_subrow2} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                History
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell>Num</TableCell>
                                        <TableCell>Temps</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Total</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Client</TableCell>
                                        <TableCell>Phone</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row?.operation_details?.length > 0 && row?.operation_details.map((operation_details, i) => (
                                        <TableRow key={i}>
                                            <TableCell component="th" scope="row">
                                                {operation_details?.date}
                                            </TableCell>
                                            <TableCell>{operation_details?.customerId}</TableCell>
                                            <TableCell align="right">{operation_details?.amount}</TableCell>
                                            <TableCell align="right">
                                                {Math?.round(operation_details?.amount * row?.price * 100) / 100}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

function PaperComponent(props) {
    return (
        <Draggable handle="#dialog1-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const SmartAutoComplete = React.memo((params) => {
    params?.filter ?
        <Autocomplete
            {...params}
        // disablePortal
        // disableListWrap
        // size="small"
        // value={dialog_item?.company1}
        // isOptionEqualToValue={(option, value) => option.id === value.id}
        // options={companies ?? []}
        // getOptionLabel={(o) => o?.name}
        // renderInput={(params) => <TextField {...params} label="Company Externe" />}
        // onChange={(e, v) => {
        //     set_dialog_item({ ...dialog_item, company1_id: v?.id, company1: v })
        //     fetchCategories({ company_id: v?.id })
        //     // load_products1({ company1_id: v?.id })
        // }}
        />
        :
        <FormControl fullWidth>
            <InputLabel id="my-select-label">{params?.placeholder}</InputLabel>
            <Select
                // onClick={() => { console.log(params) }}
                // {...params}
                // label="Select Option"
                value={params?.value?.name}
            // onChange={(e, v) => {
            //     console.log(e?.target?.value, v);
            // }}
            >
                {params?.options.map((c) => (
                    <MenuItem key={c?.id} value={c?.id}> {c?.name} </MenuItem>
                ))}
            </Select>
        </FormControl>
})

const AppCRUD = () => {
    const [data, set_data] = useState([]);
    const [rows_count, set_rows_count] = useState(1);
    const [settings, set_settings] = useState({
        per_page: 5,
        page: 1,
        filters: [
            ['company_id', JSON.parse(localStorage.getItem('company'))?.id],
            ["status_key", "in", [10, 20, 30, 40]],
        ]
    });

    const [dialog_edit_is_open1, set_dialog_edit_is_open1] = useState(false);
    const [dialog_edit_is_open, set_dialog_edit_is_open] = useState(false);
    const [dialog_edit_is_open2, set_dialog_edit_is_open2] = useState(false);
    const [dialog_item, set_dialog_item] = useState({});
    const [companies, set_companies] = useState([]);
    const [products2, set_products2] = useState([]);
    const [products1, set_products1] = useState([]);
    const [products, set_products] = useState([]);
    const [snackbar, set_snackbar] = useState(false);
    const [operation_details, set_operation_details] = useState([]);
    const [edit_loading, set_edit_loading] = useState(false);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [category, setCategory] = useState(null);
    const [permissions, set_permissions] = useState([]);
    const is_sm = useMediaQuery("(max-width:600px)");

    useEffect(() => {
        axios
            .post(`${process.env.REACT_APP_API_URL}permissions?route=datamanaging/commandese`)
            .then((res) => {
                set_permissions(res?.data)
                if (res?.data?.length == 0) {
                    localStorage.removeItem('accessToken');
                    window.history.replaceState(null, null, "/login")
                    location.pathname = '/login';
                    window.location.href = "/login";
                }
            })
            .catch(() => console.log('error!'))
    }, [])


    useEffect(() => {
        load_data();
        load_products();
        load_products2();
        load_companies();
    }, []);

    useEffect(() => {
        load_data();
    }, [settings]);

    useEffect(() => {
        if (dialog_edit_is_open1) {
            set_operation_details(dialog_item?.operation_details)
            load_products1()
            fetchCategories1()
        }
    }, [dialog_edit_is_open1]);

    useEffect(() => {
        if (dialog_edit_is_open) {
            set_operation_details(dialog_item?.operation_details)
            load_products1()
        }
    }, [dialog_edit_is_open]);

    useEffect(() => {
        if (Object.values(dialog_item.operation_details ?? []).filter(od => od?.product1_qte == 0).length > 0)
            set_dialog_item(o1 => ({
                ...o1,
                operation_details: Object.values(o1.operation_details ?? []).filter(od => od?.product1_qte > 0)
            }))
    }, [dialog_item?.operation_details]);

    // const fetchCategories = async (dialog_item = null) => {
    //     try {
    //         const response = await axios.post(`${process.env.REACT_APP_API_URL}categories`, {
    //             per_page: 200,
    //             filters: [
    //                 ['company_id', c?.id]
    //             ]
    //         });
    //         const categories = response.data.data;
    //         setCategoryOptions(categories);
    //     } catch (error) {
    //         console.log('Error fetching categories:', error);
    //     }
    // };

    const fetchCategories1 = async (dialog_item = null) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}categories`, {
                per_page: 200,
                filters: [
                    ['company_id', 'in', companies?.map(c => c?.id)]
                ]
            });

            // console.log(response.data.data);

            const data = Object.values(response.data.data).reduce((acc, item, i) => {
                // const key = `${item.company_id}-${item.name}`;
                if (!acc[item.name]) {
                    acc[item.name] = {
                        company_ids: [item?.company_id],
                        name: item?.name,
                        image: item?.image,
                        ids: [item.id],
                    };
                } else {
                    acc[item?.name].ids.push(item?.id);
                    acc[item?.name].company_ids.push(item?.company_id);
                }
                return acc;
            }, {});

            //   console.log(data);


            setCategoryOptions(Object.values(data));
            // setCategoryOptions(response.data.data);
        } catch (error) {
            console.log('Error fetching categories:', error);
        }
    };

    const load_data = async () => {
        await axios
            .post(`${process.env.REACT_APP_API_URL}operations`, settings)
            .then((res) => {
                try {
                    set_data(res?.data?.data);
                    set_rows_count(res?.data?.total);
                } catch (error) {
                    set_snackbar({ message: error?.message ?? 'Error ! Operation' });
                }
            })
            .catch((err) => set_snackbar({ message: 'Error ! Chargement de donnees' }));
    };

    const load_companies = async () => {
        await axios
            .post(`${process.env.REACT_APP_API_URL}companies`, {
                per_page: 200,
                filters: {
                    0: ['id', '!=', JSON.parse(localStorage.getItem('company'))?.id],
                    'type': 10,
                }
            })
            .then((res) => {
                set_companies(res?.data?.data);
            })
            .catch(() => console.log('error!'));
    };

    const load_products2 = async () => {
        set_products2([]);
        await axios
            .post(`${process.env.REACT_APP_API_URL}companies`, {
                per_page: 1000,
                filters: {
                    '0': ['id', '!=', JSON.parse(localStorage.getItem('company'))?.id],
                    'type': 10,
                }
            })
            .then(async (res) => {
                await axios
                    .post(`${process.env.REACT_APP_API_URL}products`, {
                        per_page: 1000,
                        filters: {
                            '0': ['products.company_id', 'in', Object.values(res?.data?.data).map(c => c?.id)]
                        }
                    })
                    .then((res) => {
                        set_products2(res?.data?.data);
                    })
                    .catch(() => console.log('error!'));
            })
            .catch(() => console.log('error!'));
    };

    const load_products = async () => {
        set_products([]);
        await axios
            .post(`${process.env.REACT_APP_API_URL}products`, {
                per_page: 1000,
                filters: {
                    'products.company_id': JSON.parse(localStorage.getItem('company'))?.id
                }
            })
            .then((res) => {
                set_products(res?.data?.data);
            })
            .catch(() => console.log('error!'));
    };

    const load_products1 = async (dialog_item) => {
        set_products1([]);
        await axios
            .post(`${process.env.REACT_APP_API_URL}products`, {
                per_page: 1000,
                filters: dialog_item
            })
            .then((res) => {
                set_products1(
                    Object.values(res?.data?.data ?? []).filter(p =>
                        products.some(product => product.ref === p.ref)
                    )
                );

                // set_products1(res?.data?.data);

                // Object.values(res?.data?.data ?? []).map((p, pi) => pi == odi ? { ...p, 
                //     product_id: 
                //     products?.filter(p => p?.ref == v?.ref)?.[0]?.id
                // , product: products?.filter(p => p?.ref == v?.ref)?.[0] } : p)
            })
            .catch(() => console.log('error!'));
    };

    const edit = async (data = dialog_item) => {
        set_edit_loading(true)
        await axios
            .put(`${process.env.REACT_APP_API_URL}operations`, data)
            .then((res) => {
                try {
                    if (res?.data?.result) {
                        load_data();
                        set_dialog_edit_is_open1(false);
                        set_dialog_edit_is_open(false);
                        set_dialog_edit_is_open2(false);
                        set_snackbar({ message: 'Operation successfully updated!', type: 'success' });
                    } else set_snackbar({ message: res?.data?.message ?? 'Error ! Edit Operation' });
                } catch (error) {
                    console.log('error : ' + 'operations' + '!');
                }
            })
            .catch(() => console.log('error!'));
        set_edit_loading(false)
    };

    const delete1 = async (data) => {
        const confirmed = window.confirm('Are you sure you want to delete this element?');
        if (!confirmed) {
            return; // If the user cancels the confirmation, exit the function
        }

        await axios
            .delete(`${process.env.REACT_APP_API_URL}operations`, { data: data })
            .then((res) => {
                try {
                    if (res?.data?.result) {
                        load_data();
                        set_snackbar({ message: 'Operation successfully deleted!', type: 'success' });
                    } else set_snackbar({ message: res?.data?.message ?? 'Error ! Delete Operation' });
                } catch (error) {
                }
            })
    };

    const onSaveAddDouble = (dialog_item) => {
        // const operations = [];
        // Object.values(dialog_item?.operation_details).forEach(od => {
        //     if (!operations?.[od?.product1?.company_id]) operations[od?.product1?.company_id] = []
        //     operations[od?.product1?.company_id].push(od);
        // });
        // operations.forEach((od, company1_id) => {
        edit({
            // company1_id: company1_id,
            status_key: dialog_item?.status_key,
            operation_details: Object.values(dialog_item?.operation_details ?? []).map(o => ({
                company1_id: o?.product1?.company_id,
                product_id: o?.product_id,
                product1_id: o?.product1_id,
                product1_qte: o?.product1_qte,
            }))
        })

        // });
    }

    const MainCardButtonAdd = (
        <Box>
            <Button
                sx={{ mx: 1 }}
                type="submit"
                variant="outlined"
                color="primary"
                onClick={() => {
                    set_dialog_item({ status_key: 10 })
                    set_dialog_edit_is_open1(true)
                }}
            >
                Commander
            </Button>
            {/* <Button
                sx={{ mx: 1 }}
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => {
                    set_dialog_item({ status_key: 10 })
                    set_dialog_edit_is_open(true)
                    // fetchCategories()

                    if (!("Notification" in window)) {
                        alert("Ce navigateur ne prend pas en charge la notification de bureau");
                    }
                    else if (Notification.permission !== "denied") {
                        Notification.requestPermission().then((permission) => {
                            console.log('Notification.permission : denied')
                        });
                    }
                    if (Notification.permission === "granted") {
                        // addNotification({
                        //     title: 'Warning',
                        //     subtitle: 'subtitle', //optional
                        //     message: 'message', //optional
                        //     onClick: (e => {
                        //         window.history.replaceState(null, null, "/datamanaging/commandes")
                        //         window.location.href = "/datamanaging/commandes";
                        //         location.pathname = '/datamanaging/commandes';
                        //         console.log('notification onClick')
                        //     }), //optional, onClick callback.
                        //     theme: 'red', //optional, default: undefined
                        //     duration: 15000, //optional, default: 5000,
                        //     backgroundTop: 'green', //optional, background color of top container.
                        //     backgroundBottom: 'darkgreen', //optional, background color of bottom container.
                        //     colorTop: 'green', //optional, font color of top container.
                        //     colorBottom: 'darkgreen', //optional, font color of bottom container.
                        //     closeButton: 'Go away', //optional, text or html/jsx element for close text. Default: Close,
                        //     native: true, //optional, makes the push notification a native OS notification
                        //     // icon?: string, // optional, Native only. Sets an icon for the notification.
                        //     vibrate: 1, // optional, Native only. Sets a vibration for the notification.
                        //     // silent: boolean // optional, Native only. Makes the notification silent.

                        // });
                    }



                    // console.log('wdqwd')

                    // }, 2000)

                    // if (!("Notification" in window)) {
                    //     console.log("Browser does not support desktop notification");
                    // } else {
                    //     console.log("Notifications are supported");
                    // }

                    // if (!("Notification" in window)) {
                    //     console.log("Browser does not support desktop notification");
                    // } else {
                    //     Notification.requestPermission();
                    // }

                    // new Notification('Hello World 1')

                    // const notification = new Notification('Hello World', {
                    //     body: 'Notification Body',
                    //     icon: 'https://www.vkf-renzel.com/out/pictures/generated/product/1/356_356_75/r12044336-01/general-warning-sign-10836-1.jpg?auto=compress&cs=tinysrgb&dpr=1&w=500',
                    //     dir: 'ltr',
                    // });

                }}
            >
                Commander
            </Button> */}
        </Box>
    );

    // Function to generate a random color in hexadecimal format
    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    return (
        <MainCard title="Commandes Envoyer" secondary={MainCardButtonAdd}>
            <Snackbar
                open={snackbar ? true : false}
                autoHideDuration={snackbar?.hide_duration ?? 3000}
                onClose={() => { set_snackbar(false); }}
            >
                <Alert
                    onClose={() => { set_snackbar(false); }}
                    severity={snackbar?.type ?? 'error'} // success error warning info
                    sx={{ width: '100%' }}
                >
                    {snackbar?.message}
                </Alert>
            </Snackbar>
            <Box>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 25, 50]}
                    component="div"
                    labelRowsPerPage="Ligne/Page"
                    count={rows_count}
                    rowsPerPage={settings?.per_page}
                    page={settings?.page - 1}
                    onPageChange={(event, newPage) => set_settings({ ...settings, page: newPage + 1 })}
                    onRowsPerPageChange={(event) => set_settings({ ...settings, page: 1, per_page: event.target.value })}
                />

                {/* <ToggleButtonGroup exclusive sx={{ whiteSpace: 'nowrap' }} aria-label="text alignment"
                    onChange={(e, v) => set_settings({ ...settings, filters: settings?.filters?.map((s, i) => i == 0 ? [v, JSON.parse(localStorage.getItem('company'))?.id] : s) })}
                >
                    <ToggleButton selected={(settings?.filters[0][0] ?? '') == 'company_id'} value='company_id'>Envoye</ToggleButton>
                    <ToggleButton selected={(settings?.filters[0][0] ?? '') == 'company1_id'} value='company1_id'>Recu</ToggleButton>
                </ToggleButtonGroup> */}

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                {(permissions?.includes('commandes.sended.table.ref') || true) && <TableCell>Ref</TableCell>}
                                {(permissions?.includes('commandes.sended.table.temps') || true) && <TableCell>Temps</TableCell>}
                                {(permissions?.includes('commandes.sended.table.societe_interne') || true) && <TableCell>Societe Interne</TableCell>}
                                {(permissions?.includes('commandes.sended.table.utilisateur_interne') || true) && <TableCell>Utilisateur Interne</TableCell>}
                                {(permissions?.includes('commandes.sended.table.societe_externe') || true) && <TableCell>Societe Externe</TableCell>}
                                {(permissions?.includes('commandes.sended.table.utilisateur_externe') || true) && <TableCell>Utilisateur Externe</TableCell>}
                                {(permissions?.includes('commandes.sended.table.status') || true) && <TableCell>Status</TableCell>}
                                {(permissions?.includes('commandes.sended.table.action') || true) && <TableCell align="center">Actions</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.length > 0 &&
                                data.map((row, i) => (
                                    <Row
                                        permissions={permissions}
                                        key={i}
                                        row={row}
                                        settings={settings}
                                        edit={edit}
                                        edit_loading={edit_loading}
                                        delete1={delete1}
                                        set_dialog_item={set_dialog_item}
                                        set_dialog_edit_is_open={set_dialog_edit_is_open}
                                    />
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 25, 50]}
                    component="div"
                    labelRowsPerPage="Ligne/Page"
                    count={rows_count}
                    rowsPerPage={settings?.per_page}
                    page={settings?.page - 1}
                    onPageChange={(event, newPage) => set_settings({ ...settings, page: newPage + 1 })}
                    onRowsPerPageChange={(event) => set_settings({ ...settings, page: 1, per_page: event.target.value })}
                />
            </Box>

            <Dialog
                open={dialog_edit_is_open1}
                onClose={() => { set_dialog_edit_is_open1(false), set_dialog_item({}), setCategoryOptions([]), set_products1([]) }}
                // fullWidth
                fullScreen
            // maxWidth="xl"
            >
                <DialogTitle id="dialog1-title">{dialog_item?.id ? 'Edit' : 'Create'} Operation</DialogTitle>
                <DialogContent dividers>

                    <Grid spacing={2} alignItems="center" justify="center">

                        {Object.values(dialog_item?.operation_details ?? []).map((od, odi) =>
                            <Grid container key={odi} alignItems="center" spacing={1} sx={{ mt: 2, border: 1, pb: 2, pr: 1, position: "relative" }}>

                                <Grid item xs={12} sm={1} justify="flex-end" justifyContent="space-between" sx={{ mb: 2 }}>
                                    <IconButton color="error"
                                        sx={{ position: "absolute", top: 5, right: 5 }} // Ajustement pour le sortir de la bordure
                                        onClick={() =>
                                            set_dialog_item(o1 => ({
                                                ...o1,
                                                operation_details: Object.values(o1.operation_details ?? []).filter((p, pi) => pi != odi)
                                            }))
                                        }
                                    >
                                        <MdDeleteOutline size={100} />
                                    </IconButton>
                                </Grid>

                                {!category && !od?.product1_id &&
                                    <Grid item xs={12} sm={12}>
                                        <ImageList cols={is_sm ? 2 : 4} gap={10}>
                                            {(categoryOptions ?? []).map((c, i) => (
                                                <ImageListItem key={c?.name} onClick={(e, v) => { setCategory(c), load_products1([['products.company_id', 'in', c?.company_ids], ['category_id', 'in', c?.ids], ['products.type', 'in', ['PF', 'PA']]]) }}>
                                                    {c?.image ? (
                                                        <img
                                                            src={process.env.REACT_APP_URL + 'storage/' + c?.image}
                                                            alt={c?.name}
                                                            loading="lazy"
                                                        />
                                                    ) : (
                                                        <Stack direction="row" spacing={2}>
                                                            <Avatar variant="square" sx={{ width: '100%', height: { xs: 100, sm: 200 }, backgroundColor: getRandomColor(), }}>
                                                                {c?.name.charAt(0).toUpperCase()}
                                                            </Avatar>
                                                        </Stack>
                                                    )}
                                                    <ImageListItemBar title={c?.name} />
                                                </ImageListItem>
                                            ))}
                                        </ImageList>
                                    </Grid>
                                }

                                {!od?.product1_id && category &&
                                    <Grid item xs={12} sm={12}>
                                        <ImageList cols={is_sm ? 1 : 4} gap={20}>
                                            {!(products1 ?? []).length &&
                                                <ImageListItem key="Subheader" cols={5}>
                                                    <ListSubheader component="div">Liste Produit Vide</ListSubheader>
                                                </ImageListItem>
                                            }
                                            {(products1 ?? []).map((v, i) => (
                                                <ImageListItem key={i} onClick={() => {

                                                    set_dialog_item(o1 => ({
                                                        ...o1,
                                                        operation_details: Object.values(o1.operation_details ?? []).map((p, pi) => pi == odi ? { ...p, product1_id: v?.id, product1: v } : p)
                                                    }))
                                                    if (v?.ref?.length > 0) {
                                                        // console.log(v?.ref);
                                                        // console.log(products);
                                                        // console.log(products?.filter(p => p?.ref == v?.ref));
                                                        set_dialog_item(o1 => ({
                                                            ...o1,
                                                            operation_details: Object.values(o1.operation_details ?? []).map((p, pi) => pi == odi ? { ...p, product_id: products?.filter(p => p?.ref == v?.ref)?.[0]?.id, product: products?.filter(p => p?.ref == v?.ref)?.[0] } : p)
                                                        }))
                                                    }

                                                }}>
                                                    {v?.image ? (
                                                        <img
                                                            src={process.env.REACT_APP_URL + 'storage/' + v?.image}
                                                            alt={v?.name}
                                                            loading="lazy"
                                                        />
                                                    ) : (
                                                        <Stack direction="row" spacing={2}>
                                                            <Avatar variant="square" sx={{ width: '100%', height: { xs: 100, sm: 200 }, backgroundColor: getRandomColor(), }}>
                                                                {v?.name.charAt(0).toUpperCase()}
                                                            </Avatar>

                                                        </Stack>
                                                    )}

                                                    <ImageListItemBar title={v?.name} />

                                                </ImageListItem>
                                            ))}
                                        </ImageList>


                                    </Grid>
                                }

                                {!od?.product_id && od?.product1 &&
                                    <Grid item xs={12} sm={3}>
                                        <Autocomplete
                                            disablePortal
                                            disableListWrap
                                            size="small"
                                            value={od?.product}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            options={products ? products?.map((p) => ({ id: p.id, name: p?.name })) : []}
                                            renderInput={(params) => <TextField {...params} label="Product Interne" />}
                                            getOptionLabel={(o2) => o2?.name}
                                            onChange={(e, v) =>
                                                set_dialog_item(o1 => ({
                                                    ...o1,
                                                    operation_details: Object.values(o1.operation_details ?? []).map((p, pi) => pi == odi ? { ...p, product_id: v?.id, product: v } : p)
                                                }))
                                            }
                                        />
                                    </Grid>
                                }

                                {od?.product_id && od?.product1_id &&
                                    <Grid container sx={{ mt: 2, mx: 3 }}>
                                        <Grid item xs={12} sm={4}>
                                            <Typography variant="h4" component="h5" sx={{ mt: 0.5 }}>
                                                {od?.product1?.name}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={3} justify="flex-end" justifyContent="space-between" sx={{ mt: 2, textAlign: 'center' }}>
                                            <IconButton color="primary" onClick={() =>
                                                set_dialog_item(o1 => ({
                                                    ...o1,
                                                    operation_details: Object.values(o1.operation_details ?? []).map((p, pi) => pi == odi ? { ...p, product1_qte: parseInt(p?.product1_qte) + 1 } : p)
                                                }))
                                            }>
                                                <MdAdd size={30} />
                                            </IconButton>
                                            <TextField sx={{ maxWidth: '100px' }} size="small" type="number"
                                                inputProps={{ style: { textAlign: 'center' } }}
                                                InputProps={{ startAdornment: od?.product1?.unit }}
                                                value={od?.product1_qte}
                                                onChange={(e) =>
                                                    set_dialog_item(o1 => ({
                                                        ...o1,
                                                        operation_details: Object.values(o1.operation_details ?? []).map((p, pi) => pi == odi ? { ...p, product1_qte: e.target.value } : p)
                                                    }))
                                                }
                                            />
                                            <IconButton color="error" onClick={() =>
                                                set_dialog_item(o1 => ({
                                                    ...o1,
                                                    operation_details: Object.values(o1.operation_details ?? []).map((p, pi) => pi == odi ? { ...p, product1_qte: p?.product1_qte - 1 } : p)
                                                }))
                                            }>
                                                <MdRemove size={30} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                }

                            </Grid>
                        )}

                        <Grid item xs={1} sx={{ mt: 3 }}>
                            <Button variant="contained" color="primary"
                                onClick={() => {
                                    set_dialog_item(o1 => ({
                                        ...o1,
                                        operation_details: { ...o1.operation_details, [Object.values(dialog_item?.operation_details ?? []).length]: { product1_qte: 1 } }
                                    }))
                                    setCategory(null)
                                }}
                            >
                                Ajoute Produit
                            </Button>
                        </Grid>
                    </Grid>

                </DialogContent>

                <DialogActions>
                    {!edit_loading && <Button onClick={() => set_dialog_edit_is_open1(false)}>Cancel</Button>}
                    <LoadingButton
                        color="primary"
                        loading={edit_loading}
                        loadingPosition="start"
                        startIcon={<MdSave />}
                        variant="contained"
                        onClick={() =>
                            onSaveAddDouble(dialog_item)
                        }
                    >
                        Save{edit_loading && '...'}
                    </LoadingButton>
                </DialogActions>

                {/* <DialogActions>
                    {!edit_loading && <Button onClick={() => set_dialog_edit_is_open1(false)}>Cancel</Button>}
                    <LoadingButton
                        color="primary"
                        loading={edit_loading}
                        loadingPosition="start"
                        startIcon={<MdSave />}
                        variant="contained"
                        onClick={() => {
                            edit({
                                id: dialog_item?.id,
                                company1_id: dialog_item?.company1_id,
                                status_key: dialog_item?.status_key,
                                payment_method: dialog_item?.payment_method,
                                operation_details: Object.values(dialog_item?.operation_details ?? []).map(o => ({
                                    product_id: o?.product_id,
                                    product1_id: o?.product1_id,
                                    product1_qte: o?.product1_qte,
                                })),
                            })
                            setCategory(null)
                        }}
                    >
                        Save{edit_loading && '...'}
                    </LoadingButton>
                </DialogActions> */}

            </Dialog>

            <Dialog
                open={dialog_edit_is_open2}
                onClose={() => set_dialog_edit_is_open2(false)}
                fullWidth
                maxWidth="md"
                PaperComponent={PaperComponent}
            >
                <DialogTitle id="dialog1-title">{dialog_item?.id ? 'Edit' : 'Create'} Operation</DialogTitle>
                <DialogContent dividers>
                    {dialog_item?.status_key && dialog_item?.status_key != 200 &&
                        <>
                            {/* <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <Autocomplete
                                        disablePortal
                                        disableListWrap
                                        value={dialog_item?.company1}
                                        options={companies ?? []}
                                        getOptionLabel={(o) => o?.name}
                                        renderInput={(params) => <TextField {...params} label="Company Externe" />}
                                        onChange={(e, v) => {
                                            set_dialog_item({ ...dialog_item, company1_id: v?.id, company1: v })
                                            load_products1({ company1_id: v?.id })
                                        }}
                                    />
                                </Grid>
                                {dialog_item?.status_key && dialog_item?.status_key == 300 && (
                                    <Grid item xs={12} sm={6}>
                                        <Autocomplete
                                            disablePortal
                                            disableListWrap
                                            value={dialog_item}
                                            options={payment_methods ?? []}
                                            getOptionLabel={(o) => o?.payment_method}
                                            renderInput={(params) => <TextField {...params} label="Payment Method" onBlur={(e) => { if (dialog_item?.payment_method?.length > 0 && payment_methods.filter(pm => pm?.payment_method == e.target.value).length == 0) { set_payment_methods((o) => [...o, { payment_method: e.target.value }]) } set_dialog_item({ ...dialog_item, payment_method: e.target.value }) }} />}
                                            onChange={(e, v) => set_dialog_item({ ...dialog_item, payment_method: v?.payment_method })}
                                        />
                                    </Grid>
                                )}
                            </Grid> */}

                            {/* <Grid item xs={12} sx={{ marginTop: 0 }}>
                                <Typography variant="h6" gutterBottom>
                                    Details :
                                </Typography>
                            </Grid> */}
                        </>
                    }

                    <Grid spacing={2} alignItems="center" justify="center" sx={{ mt: 0 }}>

                        <Grid item xs={1}>
                            <Button variant="contained" color="primary"
                                onClick={() => {
                                    set_dialog_item(o1 => ({
                                        ...o1,
                                        operation_details: { ...o1.operation_details, [Object.values(dialog_item?.operation_details ?? []).length]: { product1_qte: 1 } }
                                    }))
                                    setCategory(null)
                                }}
                            >
                                Ajoute Produit
                            </Button>
                        </Grid>

                        {Object.values(dialog_item?.operation_details ?? []).map((od, odi) =>
                            <Grid container alignItems="center" spacing={2} sx={{ mt: 2, border: 1, pb: 2, pr: 1 }}>

                                <Grid item xs={12} sm={5}>
                                    <Autocomplete
                                        disablePortal
                                        disableListWrap
                                        size="small"
                                        value={od?.product1}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        options={products2 ? products2?.map((p) => ({ id: p.id, name: p?.name, ref: p?.ref, company_id: p?.company_id, unit: p?.unit })) : []}
                                        renderInput={(params) => <TextField {...params} label="Product Externe" />}
                                        getOptionLabel={(o1) => o1?.name}
                                        onChange={(e, v) => {
                                            set_dialog_item(o1 => ({
                                                ...o1,
                                                operation_details: Object.values(o1.operation_details ?? []).map((p, pi) => pi == odi ? { ...p, product1_id: v?.id, product1: v } : p)
                                            }))
                                            if (v?.ref?.length > 0)
                                                set_dialog_item(o1 => ({
                                                    ...o1,
                                                    operation_details: Object.values(o1.operation_details ?? []).map((p, pi) => pi == odi ? { ...p, product_id: products?.filter(p => p?.ref == v?.ref)?.[0]?.id, product: products?.filter(p => p?.ref == v?.ref)?.[0] } : p)
                                                }))
                                        }}
                                    />
                                </Grid>

                                {!od?.product_id &&
                                    <Grid item xs={12} sm={4}>
                                        <Autocomplete
                                            disablePortal
                                            disableListWrap
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            size="small"
                                            value={od?.product}
                                            options={products ? products?.map((p) => ({ id: p.id, name: p?.name })) : []}
                                            renderInput={(params) => <TextField {...params} label="Product Interne" />}
                                            getOptionLabel={(o2) => o2?.name}
                                            onChange={(e, v) =>
                                                set_dialog_item(o1 => ({
                                                    ...o1,
                                                    operation_details: Object.values(o1.operation_details ?? []).map((p, pi) => pi == odi ? { ...p, product_id: v?.id, product: v } : p)
                                                }))
                                            }
                                        />
                                    </Grid>
                                }

                                <Grid item xs={12} sm={3} sx={{ textAlign: 'center' }} justify="flex-end" justifyContent="space-between">
                                    <IconButton color="primary" onClick={() =>
                                        set_dialog_item(o1 => ({
                                            ...o1,
                                            operation_details: Object.values(o1.operation_details ?? []).map((p, pi) => pi == odi ? { ...p, product1_qte: parseInt(p?.product1_qte) + 1 } : p)
                                        }))
                                    }>
                                        <MdAdd />
                                    </IconButton>
                                    <TextField sx={{ maxWidth: '100px' }} size="small" type="number"
                                        inputProps={{ style: { textAlign: 'center' } }}
                                        InputProps={{ startAdornment: od?.product1?.unit }}
                                        value={od?.product1_qte}
                                        onChange={(e) =>
                                            set_dialog_item(o1 => ({
                                                ...o1,
                                                operation_details: Object.values(o1.operation_details ?? []).map((p, pi) => pi == odi ? { ...p, product1_qte: e.target.value } : p)
                                            }))
                                        }
                                    />
                                    <IconButton color="error" onClick={() =>
                                        set_dialog_item(o1 => ({
                                            ...o1,
                                            operation_details: Object.values(o1.operation_details ?? []).map((p, pi) => pi == odi ? { ...p, product1_qte: p?.product1_qte - 1 } : p)
                                        }))
                                    }>
                                        <MdRemove />
                                    </IconButton>
                                </Grid>

                            </Grid>
                        )}
                    </Grid>
                </DialogContent>

                <DialogActions>
                    {!edit_loading && <Button onClick={() => set_dialog_edit_is_open2(false)}>Cancel</Button>}
                    <LoadingButton
                        color="primary"
                        loading={edit_loading}
                        loadingPosition="start"
                        startIcon={<MdSave />}
                        variant="contained"
                        onClick={() =>
                            onSaveAddDouble(dialog_item)
                        }
                    >
                        Save{edit_loading && '...'}
                    </LoadingButton>
                </DialogActions>

            </Dialog>
        </MainCard >
    );
};

export default AppCRUD;
