import { useRoutes, useLocation } from 'react-router-dom';

// project import
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import axios from 'axios';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {

    const location = useLocation();

    if (localStorage.getItem("accessToken") == null) {
        window.history.replaceState(null, null, "/login")
        location.pathname = '/login';
    }
    else if (localStorage.getItem("accessToken") != null && location.pathname == '/login') {
        window.history.replaceState(null, null, "/")
        location.pathname = '/';
    }

    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error?.response?.status == 401) {
                localStorage.removeItem('accessToken');
                window.history.replaceState(null, null, "/login")
                location.pathname = '/login';
                window.location.href = "/login";
            } else console.log(error?.response?.data);
            return Promise.reject(error);
        }
    );

    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('accessToken') ?? ''}`;

    const ua = navigator.userAgent, platform = navigator.platform;

    axios.defaults.headers.common.id = [
        /Windows NT/.test(ua) ? 'Windows' :
            /Mac OS X/.test(ua) ? 'macOS' :
                /Linux/.test(ua) ? 'Linux' :
                    /Android/.test(ua) ? 'Android' :
                        /iPhone|iPad/.test(ua) ? 'iOS' : 'Unknown OS',

        /Mobile|Android|iPhone|iPad/.test(ua) ? 'Mobile' : 'Desktop',
        `${screen.width}x${screen.height}`,
        (ua.match(/(Chrome|Firefox|Safari)\/(\d+\.\d+)/) || [])[1] || 'Unknown Browser',
        /Win32|i386/.test(platform) ? '32bit' : '64bit',
        navigator.language
    ].join('-');

    // const userAgent = navigator.userAgent;
    // const screenWidth = screen.width;
    // const screenHeight = screen.height;
    // const language = navigator.language;

    // axios.defaults.headers.common.id = `${userAgent}-${screenWidth}-${screenHeight}-${language}`;

    return useRoutes([MainRoutes, LoginRoutes]);
}
