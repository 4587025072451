import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button, InputLabel,
  DialogActions, DialogContent, Grid, Dialog, DialogTitle, Snackbar, TableContainer, TablePagination, TextField,
  Alert, Paper,
} from '@mui/material';

import { MdAdd } from "react-icons/md";
import LinearProgress from '@mui/material/LinearProgress'
import moment from 'moment'
import MainCard from 'components/MainCard';
import { Autocomplete, Divider } from '../../../node_modules/@mui/material/index';
import { iteratee } from 'lodash';


const PermissionsTable = () => {
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [file, set_file] = useState('');

  const [rows_count, set_rows_count] = useState(1)
  const [settings, set_settings] = useState({
    per_page: 5,
    page: 1,
    with: ['companies']
  })
  const [max_updated_at, set_max_updated_at] = useState(0)
  const [progress_num, set_progress_num] = useState(0)
  const [progress_seconde, set_progress_seconde] = useState(60 * 1)
  const [dialog_item, set_dialog_item] = useState({})
  const [snackbar, set_snackbar] = useState(false);

  const [dialog_edit_is_open, set_dialog_edit_is_open] = useState(false)
  const [dialog_view_is_open, set_dialog_view_is_open] = useState(false)
  const [dialog_view_item, set_dialog_view_item] = useState({})
  const [companies, set_companies] = useState([]);
  const [image, setImage] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      set_progress_num((moment().format('s') % progress_seconde + 1) / progress_seconde * 100)
    }, 500)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    fetchPermissions();
    axios
      .post(`${process.env.REACT_APP_API_URL}companies`, { per_page: 200, filters: { type: 10 } })
      .then((res) => set_companies(res?.data?.data))
  }, []);

  const handleImageChange = (event) => {
    const file = event?.target?.files?.[0];
    set_file(file)
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImage(reader?.result);
    };
    // console.log(file);
  };

  const fetchPermissions = async () => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_URL + 'permissions?route=datamanaging/categories');
      const permissions = response.data;

      // Extract columns after "categories.table1"
      const tableColumns = permissions.filter(permission => permission.startsWith('categories.table1.'));
      setColumns(tableColumns);

      setLoading(false);
    } catch (error) {
      console.error('Error fetching permissions:', error);
    }
  };

  useEffect(() => {
    load_data();
  }, [settings]);

  const load_data = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}categories`, settings);
      setData(response.data.data);
      set_rows_count(response?.data?.total)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleCreate = () => {
    // handle create functionality
    // console.log('Creating a new item');
    // Check if the 'btn_create' permission is available
    if ((columns.includes('categories.table1.btn_create') || true)) {
      set_dialog_item({});  // This will reset the dialog item to be empty for a new item creation
      set_dialog_edit_is_open(true);  // This will open the dialog to input data for new item
    } else {
      console.log('You do not have permission to create new item');
      set_snackbar({ message: 'You do not have permission to create a category.', type: 'error', hide_duration: 3000 });
    }
  };

  const handleEdit = async (data = dialog_item) => {
    // handle edit functionality
    // console.log('Edit item', item);

    // Check if the 'btn_row_edit' permission is available
    if ((columns.includes('categories.table1.btn_row_edit') || true)) {

      let d = new FormData()
      if (file) d.append("file", file)
      Object.entries(dialog_item).forEach(([k, v]) => { if (v && k != 'companies') d.append(k, v) });

      if (dialog_item?.companies) dialog_item?.companies?.forEach((pc, i) => {
        d.append(`companies[${i}][id]`, pc?.id);
      });

      await axios
        .post(`${process.env.REACT_APP_API_URL}categorie`, d)
        .then((res) => {
          try {
            if (res?.data?.result) {
              load_data();
              set_dialog_edit_is_open(false);
              set_file("")
              setImage("")
              set_snackbar({ message: 'Category successfully updated!', type: 'success' });
            }
            else set_snackbar({ message: res?.data?.message ?? 'Error! Edit Categorie' });
          } catch (error) {
            console.log('error: ' + 'categories' + '!');
          }
        })
        .catch(() => console.log('error!'));
    } else {
      console.log('You do not have permission to edit');
    }
  };

  const handleDelete = async (itemId) => {
    const confirmed = window.confirm('Are you sure you want to delete this category?');
    if (!confirmed) {
      return; // If the user cancels the confirmation, exit the function
    }

    await axios
      .delete(`${process.env.REACT_APP_API_URL}categories`, { params: { id: itemId } })
      .then((res) => {
        if (res?.data?.result) {
          load_data();
          set_snackbar({ message: 'Category successfully deleted.', type: 'success', hide_duration: 3000 });
        } else {
          set_snackbar({ message: res?.data?.message ?? 'Error! Delete Category', type: 'error', hide_duration: 3000 });
        }
      })
      .catch((error) => {
        set_snackbar({ message: 'Error deleting category.', type: 'error', hide_duration: 3000 });
        console.log('error:', error);
      });
  };

  const handleView = (item) => {
    // handle view functionality
    // console.log('View item', item);
    set_dialog_view_item(item);
    set_dialog_view_is_open(true);
  };

  if (loading) {
    return <CircularProgress />;
  }

  // Button checking if the 'btn_create' permission is available
  const MainCardButtonAdd = (columns.includes('categories.table1.btn_create') || true) ?
    <Button type="submit" variant="contained" color="primary" onClick={handleCreate}>Create</Button>
    : null;

  const openEditDialog = (item) => {
    set_dialog_item(item);
    set_dialog_edit_is_open(true);
  };

  return (
    <MainCard title="Categories" secondary={MainCardButtonAdd}>
      <Snackbar open={snackbar ? true : false} autoHideDuration={snackbar?.hide_duration ?? 3000} onClose={() => { set_snackbar(false) }}>
        <Alert onClose={() => { set_snackbar(false) }} severity={snackbar?.type ?? 'error'} sx={{ width: '100%' }}>{snackbar?.message}</Alert>
      </Snackbar>

      <LinearProgress variant="determinate" value={progress_num} sx={{ mb: 2 }} />

      <Box>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 25, 50]}
          component="div"
          labelRowsPerPage="Ligne/Page"
          count={rows_count}
          rowsPerPage={settings?.per_page}
          page={settings?.page - 1}
          onPageChange={(event, newPage) => set_settings({ ...settings, page: newPage + 1 })}
          onRowsPerPageChange={(event) => set_settings({ ...settings, page: 1, per_page: event.target.value })}
        />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {/* {(columns?.includes('categories.table1.id') || true) && <TableCell>Id</TableCell>} */}
                {(columns?.includes('categories.table1.name') || true) && <TableCell>Name</TableCell>}
                {(columns?.includes('categories.table1.image') || true) && <TableCell>Image</TableCell>}
                {/* {(columns?.includes('categories.table1.description') || true) && <TableCell>Description</TableCell>} */}
                {/* {(columns?.includes('categories.table1.create_at') || true) && <TableCell>Created At</TableCell>} */}
                {/* {(columns?.includes('categories.table1.edited_at') || true) && <TableCell>Edited At</TableCell>} */}
                {((columns?.includes('categories.table1.btn_row_edit') || true) || (columns?.includes('categories.table1.btn_row_delete') || true) || (columns?.includes('categories.table1.btn_row_view') || true)) && <TableCell>Actions</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(item => (
                <TableRow key={item.id}>
                  {(columns?.includes('categories.table1.name') || true) && <TableCell>{item?.name}</TableCell>}
                  {(columns?.includes('categories.table1.image') || true) &&
                    <TableCell>
                      <img
                        style={{ width: 50, height: 50 }}
                        src={process.env.REACT_APP_URL + 'storage/' + item?.image}
                        alt={item?.name}
                      />
                    </TableCell>
                  }
                  {/* {(columns?.includes('categories.table1.description') || true) && <TableCell>{item?.description}</TableCell>} */}
                  {/* {(columns?.includes('categories.table1.create_at') || true) && <TableCell>{item.created_at}</TableCell>} */}
                  {/* {(columns?.includes('categories.table1.edited_at') || true) && <TableCell>{item.edited_at}</TableCell>} */}
                  {((columns?.includes('categories.table1.btn_row_edit') || true) || (columns?.includes('categories.table1.btn_row_delete') || true) || (columns?.includes('categories.table1.btn_row_view') || true)) &&
                    <TableCell>
                      <Grid container spacing={2}>
                        <Grid item>
                          {(columns?.includes('categories.table1.btn_row_edit') || true) && <Button variant="outlined" color="primary" onClick={() => openEditDialog(item)}>Edit</Button>}
                        </Grid>
                        <Grid item>
                          {(columns?.includes('categories.table1.btn_row_delete') || true) && <Button variant="outlined" color="error" onClick={() => handleDelete(item.id)}>Delete</Button>}
                        </Grid>
                        <Grid item>
                          {(columns?.includes('categories.table1.btn_row_view') || true) && <Button variant="outlined" color="warning" onClick={() => handleView(item)}>View</Button>}
                        </Grid>
                      </Grid>
                    </TableCell>}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 25, 50]}
          component="div"
          labelRowsPerPage="Ligne/Page"
          count={rows_count}
          rowsPerPage={settings?.per_page}
          page={settings?.page - 1}
          onPageChange={(event, newPage) => set_settings({ ...settings, page: newPage + 1 })}
          onRowsPerPageChange={(event) => set_settings({ ...settings, page: 1, per_page: event.target.value })}
        />
      </Box>

      <Dialog key={dialog_item?.id || 'create'} open={dialog_edit_is_open} onClose={() => set_dialog_edit_is_open(false)} fullWidth maxWidth="md">
        <DialogTitle id="dialog1-title">{dialog_item?.id ? 'Edit' : 'Create'} Categorie</DialogTitle>
        <DialogContent dividers>
          <Grid item xs={12} sm={12} sx={{ mb: 2 }}>
            <InputLabel>Image*</InputLabel>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: 'none' }}
                id="image-upload"
              />
              <label htmlFor="image-upload">
                <Button variant="contained" color="primary" component="span">
                  Choose Image
                </Button>
              </label>
              <a href={process.env.REACT_APP_URL + 'storage/' + dialog_item?.image} target='_blank'>
                <img src={image != '' ? image  : (process.env.REACT_APP_URL + 'storage/' + dialog_item?.image)} alt="" width="auto" height="300" style={{ marginLeft: '16px' }} />
              </a>
            </div>
          </Grid>
          <TextField
            margin="dense"
            label="Name"
            size="small"
            fullWidth
            value={dialog_item?.name || ''}
            onChange={(e) => set_dialog_item({ ...dialog_item, name: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Description"
            size="small"
            fullWidth
            value={dialog_item?.description || ''}
            onChange={(e) => set_dialog_item({ ...dialog_item, description: e.target.value })}
          />

          <Divider sx={{ my: 2 }} />

          <Grid item container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5" gutterBottom>
                Commander Par :
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary"
                onClick={() =>
                  set_dialog_item(o1 => ({
                    ...o1,
                    companies: { ...o1.companies, [Object.values(dialog_item?.companies ?? []).length]: {} }
                  }))
                }
              >
                Ajoute Une Sociéte <MdAdd />
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12} spacing={2} alignItems="center" justify="center" sx={{ ml: 2 }}>

            {Object.values(dialog_item?.companies ?? []).map((c, ci) =>
              <Grid container alignItems="center" spacing={2} key={ci} sx={{ border: 1, pb: 1, mt: 2, pr: 2 }}>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    disablePortal
                    disableListWrap
                    size="small"
                    value={c}
                    options={companies ?? []}
                    renderInput={(params) => <TextField {...params} label="Company" />}
                    getOptionLabel={(o2) => o2?.name ?? ''}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    onChange={(e, v) => {
                      set_dialog_item(o1 => ({
                        ...o1,
                        companies: Object.values(o1.companies ?? []).map((p, pi) => pi == ci ? v : p)
                      }))
                    }}
                  />
                </Grid>

              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => set_dialog_edit_is_open(false)}>Cancel</Button>
          <Button
            onClick={() => {
              handleEdit();
            }}
            color="primary"
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog key={dialog_view_item?.id || 'view'} open={dialog_view_is_open} onClose={() => set_dialog_view_is_open(false)} fullWidth maxWidth="sm">
        <DialogTitle id="dialog1-title">View Categorie</DialogTitle>
        <DialogContent dividers>
          <TextField
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            value={dialog_view_item?.name || ''}
            disabled
          />
          <TextField
            margin="dense"
            id="description"
            label="Description"
            type="text"
            fullWidth
            value={dialog_view_item?.description || ''}
            disabled
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => set_dialog_view_is_open(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </MainCard>
  );
};

export default PermissionsTable;
